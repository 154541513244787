import { LogManager } from 'aurelia-framework';
import { Dictionary } from 'lodash';
import { type } from 'os';
import get from 'lodash.get';
import set from 'lodash.set';
const logger = LogManager.getLogger('dfp:chart-editor-utils');

// helper functions for data wrangling with chartjs
export type Stats = 'Minimum' | 'Maximum' | 'Count' | 'Sum' | 'Mean'

/**
 * Function for flattening object, note this is not good if duplicate keys are present
 * @param inObject object to flatten, can contain nested objects
 * @returns flat object
 */
export function flattenObject(inObject: Record<string, any>): any {
    const flat = {};
    Object.keys(inObject)
        .filter((key) => !key.startsWith('__'))
        .forEach((key) => {
            const val = inObject[key];

            if (typeof val === 'object' && val !== null && !Array.isArray(val)) {
                Object.assign(flat, flattenObject(val));
            } else {
                flat[key] = val;
            }
        });

    return flat;
}

/**
 * recursive way to return all values based on an input search key, works on nested objects
 * @param inObject in object we need the values from
 * @param searchKey the string of the key we are looking to return values for
 * @param results an array of values that may be added to recursively depending on whether or not nested objects are encountered
 * @returns final array of values
 */
export function keySearch(inObject: any, searchKey: string, results: Array<any> = []): Array<any> {

    // dont do anything for none field
    if (searchKey === 'none') {
        return [];
    }

    const res = results;
    // loop through each key
    Object.keys(inObject).forEach((key) => {
        // get the value
        const val = inObject[key];
        // if the key matches our search key and the value isn't an object, add it to the results
        if (key === searchKey && typeof val !== 'object') {
            res.push(val);
            // otherwise call it again
        } else if (val && typeof val === 'object' && !key.includes('__')) {
            keySearch(val, searchKey, res);
        }
    });
    return res;
}

/**
 * this is the main function for formatting the data for ChartJS. It takes the grouped data, rearranges it into dataset(s), and calculates the count metric
 * @param fieldType this is the string used for checking if the categorical field is a date field
 * @param inGroup this is the main data group that comes from the lodash groupby on the data from the api
 * @param splitFields if we have a split-by field, this is it
 * @returns labels and data, in the case of split-by fields, the data value will be an array of datasets, otherwise it will just be the data array
 */
export function totalCountAndLabels(fieldType: string, inGroup: Record<string, Array<any> | Dictionary<any>>, splitFields?: Array<string | number>, splitFieldType?: string): { labels: Array<string | number>, data: Array<number> } {
    const date = fieldType === 'date' ? true : false;
    const splitDate = splitFieldType === 'date' ? true : false;

    let labels = Object.keys(inGroup);

    let count = [];
    let datasets = [];

    // if we have a split field, we need a dataset for each field value
    if (splitFields) {
        splitFields.forEach((splitField) => {
            datasets.push({ label: splitField, data: [] });
        });
    }

    labels.forEach((label) => {
        // check keys
        if (Array.isArray(inGroup[label])) {
            // if its not nested, just push the count
            count.push(inGroup[label].length);
        } else {
            // we need to use multiple datasets since we have a split field (i.e. nested objects)
            const nestedObj = inGroup[label];

            const entries = Object.entries(nestedObj);
            const populate = populateDatasets(entries, datasets, false);
            datasets = populate['datasets'];
        }
    });

    // if we have datasets, use those
    if (datasets.length > 0) {
        // if they are split by date, clean those
        if (splitDate) {
            datasets.forEach((ds: { label: string, data: string[] | number[] }) => {
                ds.label = ds.label.split('T')[0];
            });
        }
        count = datasets;
    }
    // deal with category datetimes, do this last because the full datetime is needed for organizing the data
    if (date) {
        labels = simplifyDates(labels);
    }

    return { labels: labels, data: count };
}

/**
 * this function is similar to the above function but calculates statistics rather than simply the count
 *  it contains additional logic for data cleaning and calculating the metrics on multiple datasets, some logic could be cleaned up and moved into smaller functons
  * @param fieldType this is the string used for checking if the categorical field is a date field
 * @param inGroup this is the main data group that comes from the lodash groupby on the data from the api
 * @param fieldName the field name of the field we are using for the statistics calc
 * @param statistic the statistic we are calculating and gets passed into the getStats function
 * @param splitFields if we have a split-by field, this is it
 * @returns labels and data, in the case of split-by fields, the data value will be an array of datasets, otherwise it will just be the data array
 */
export function statsAndLabels(fieldType: string, inGroup: Record<string, Array<any> | Dictionary<any>>, fieldName: string, statistic: Stats, splitFields?: Array<string | number>, splitFieldType?: string, statFieldPath?: string): { labels: Array<string | number>, data: Array<number> } {
    const date = fieldType === 'date' ? true : false;
    const splitDate = splitFieldType === 'date' ? true : false;

    // get the initial labels
    let labels = Object.keys(inGroup);
    const undefinedIndex = labels.indexOf('undefined');
    // let's remove the undefined data cause it'll cause problems during calculations
    if (undefinedIndex !== -1) {
        labels.splice(undefinedIndex, 1);
    }

    // final output will be datasets if there is a split field, or statVal if there is not
    const datasets = [];
    let statVal = [];

    // setup multiple datasets if there is a splitField
    if (splitFields) {
        splitFields.forEach((splitField) => {
            datasets.push({ label: splitField, data: [] });
        });
    }

    labels.forEach((label) => {
        // if this isn't an array it means we have nested datasets
        if (!Array.isArray(inGroup[label])) {
            // we need to use multiple datasets since we have a split field (i.e. nested objects)
            let subDataset = [];
            // create a unique copy of the dataset and clear it out
            datasets.forEach((ds) => subDataset.push(Object.assign({}, ds)));
            subDataset.map((sd) => sd.data = []);
            const nestedObj = inGroup[label];
            const entries = Object.entries(nestedObj);
            const populate = populateDatasets(entries, subDataset, true, statFieldPath);
            subDataset = populate['datasets'];

            // now it is time to generate some statistics and append the final results
            subDataset.forEach((sds) => {
                const val = getStats(sds.data, statistic);
                const i = datasets.findIndex((i) => i?.label === sds.label);
                datasets[i].data.push(val);
            });

        } else {
            // use the keysearch to get the labels by fieldname
            const statByLabel = keySearch(inGroup[label], fieldName);
            // make sure that we have numbers, otherwise try parsing it
            const allNums = statByLabel.every((item) => typeof item === 'number');
            if (!allNums) {
                statByLabel.forEach((item) => {
                    if (typeof item === 'string') {
                        try {
                            const newVal = parseInt(item);
                            const index = statByLabel.indexOf(item);
                            statByLabel[index] = newVal;
                        }
                        catch {
                            logger.debug('invalid data in statsAndLabels');
                        }
                    } else if (typeof item !== 'number') {
                        logger.debug('problematic data in the stats calculation, need to review input');
                    }
                });
            }
            const val = getStats(statByLabel, statistic);
            statVal.push(val);
        }
    });

    // it is important to handle the date stuff last as it uses the datetimes for filtering and grouping
    // if we have datasets, use those
    if (datasets.length > 0) {
        if (splitDate) {
            datasets.forEach((ds: { label: string, data: string[] | number[] }) => {
                ds.label = ds.label.split('T')[0];
            });
        }
        statVal = datasets;
    }

    if (date) {
        labels = simplifyDates(labels);
    }

    return { labels: labels, data: statVal };
}

/**
 * this function prepares chartJS datasets by first populating the appropriate values, and then filling them in if necessary
 * @param entries the entries to iterate over and calculate statistics
 * @param datasets this in dataset or datasets (in case of split field)
 * @param stats if we are doing a statistics calculatio
 * @param fieldName required for stats calcs, the fieldname of corresponding to values you use
 * @returns
 */
export function populateDatasets(entries: any[], datasets: any[], stats: boolean, fieldName?: string): { datasets: any[] } {
    if (stats && fieldName == undefined) {
        logger.debug('must define field name for populate datasets if you are calculating statistics');
    }
    const checked = [];
    entries.forEach((key, value) => {
        // find the index of the corresponding dataset
        const index = datasets.findIndex((i) => i?.label === key[0]);
        if (index !== -1) {
            if (Array.isArray(key[1])) {
                if (stats) {
                    key[1].forEach((val) => datasets[index].data.push(parseInt(get(val, fieldName))));
                    // it exists, so lets populate a nice value for it
                    checked.push({ label: key[0] });
                } else {
                    // doing a count
                    // it exists, so lets populate a nice value for it
                    datasets[index].data.push(key[1].length);
                    checked.push({ label: key[0], data: [key[1]] });
                }
            }
        }
    });

    if (checked.length < datasets.length) {
        datasets = datasetDiff(datasets, checked);
    }

    return { datasets: datasets };
}

/**
 * Function for generating simple statistics based on an input array
 * @param statByLabel array of numbers
 * @param statistic what statistic to calculate
 * @returns the calculated statistic
 */
export function getStats(statByLabel: Array<number>, statistic: string): number {
    let val = undefined;
    if (statByLabel.length > 0) {
        val = statistic === 'Minimum' ? Math.min(...statByLabel) : statistic === 'Maximum' ? Math.max(...statByLabel) : statistic === 'Sum' ? statByLabel.reduce((a, b) => a + b) : statistic == 'Mean' ? statByLabel.reduce((a, b) => a + b) / statByLabel.length : [];
    } else {
        val = undefined;
    }
    return val;
}

/**
 * This functions will place a 0 as a placeholder where needed because chartjs requires the data and label arrays to have the same legth so it can match everything
 * @param dataset1 input dataset to filter
 * @param dataset2 dataset to check if label exists
 * @returns
 */
export function datasetDiff(dataset1: Array<{ label: string, data?: Array<number> }>, dataset2: Array<{ label: string, data?: Array<number> }>): Array<{ label: string, data?: Array<number> }> {
    const different = dataset1.filter(({ label: label1 }) => !dataset2.some(({ label: label2 }) => label1 === label2));
    // loop over them and add a value to the datasets
    different.forEach((diff) => {
        const i = dataset1.findIndex((i) => i?.label === diff.label);
        dataset1[i].data.push(0);
    });
    return dataset1;
}

export function simplifyDates(labels: string[]): string[] {
    return labels.map((label) => label.split('T')[0]);
}

/**
 * This function handles form submission data for a chart's date interval and set date range.
 * @param data an array of form submission data for a chart
 * @param path the path of the chart's selected date category field
 * @param timeInterval the time interval for the chart (day | week | month | quarter | year)
 * @param dateRange the date range for the chart, can be empty. 'YYYY-MM-DD to YYYY-MM-DD'
 * @returns an array of form submission data with filtered and altered date strings
 */
export function groupAndFilterDates(data: any[], path: string, timeInterval: string, dateRange ?: string) {
    if (!data) return [];

    const start = dateRange ? new Date(dateRange.split(' ')[0]) : undefined;
    const end = dateRange ? new Date(dateRange.split(' ')[2]) : undefined;

    return data.filter((datum) => {
        const oldDate = new Date(get(datum, path));
        const inRange = oldDate >= start && oldDate <= end || !dateRange;
        if (inRange) {
            switch (timeInterval) {
            case 'week': {
                oldDate.setUTCDate(oldDate.getUTCDate() - oldDate.getUTCDay());
                oldDate.setUTCHours(0, 0, 0, 0);
                break;
            }
            case 'month': {
                oldDate.setUTCHours(0, 0, 0, 0);
                oldDate.setUTCDate(1);
                break;
            }
            case 'quarter': {
                const month = oldDate.getUTCMonth();
                const quarterMonth = month > 8 ? 9 : month > 5 ? 6 : month > 2 ? 3 : 0;
                oldDate.setUTCMonth(quarterMonth);
                oldDate.setUTCHours(0, 0, 0, 0);
                oldDate.setUTCDate(1);
                break;
            }
            case 'year': {
                oldDate.setUTCMonth(0);
                oldDate.setUTCHours(0, 0, 0, 0);
                oldDate.setUTCDate(1);
                break;
            }
            }
            set(datum, path, oldDate.toISOString());
            return datum;
        }
    });
}
